import React, {useEffect, useMemo} from 'react';
import './App.css';
import {MainView} from "./components/views/main";
import MatterGame from "./components/views/game/game";
import {EarnView} from "./components/views/earn";
import {
    createBrowserRouter, Route, Router,
    RouterProvider, Routes, useNavigate,
} from "react-router-dom";
import {BoostView} from "./components/views/boost";
import {InviteView} from "./components/views/invite";
import {initNavigator} from '@telegram-apps/sdk-react';
import {useIntegration} from "@telegram-apps/react-router-integration";


import {postEvent, retrieveLaunchParams} from '@telegram-apps/sdk';

import {API_KEY, post} from "./api/apiClient";
import {
    authHandler,
    useBuyingInvoiceLink,
    useBuyingPrice,
    useFriends,
    useTaskGroups,
    useUserInfo
} from "./api/requests/user-info";
import {LoadingView} from "./components/views/loading";
import {useUserStore} from "./store/user-store";
import {useAreas, useBoosts} from "./api/requests/area";
import {useAreasStore} from "./store/areas-store";
import {useChannels, useLinks} from "./api/requests/channels";
import {useChannelsStore} from "./store/channels-store";
import {useLinksStore} from "./store/links-store";
import {useLocalImagePreload} from "./hook/useImagePreload";
import {useBoostsStore} from "./store/boosts-store";
import {useFriendsStore} from "./store/friends-store";
import {BackButtonProvider} from "./components/back-button-provider";
import {useTaskStore} from "./store/task-store";
import {TaskView} from "./components/views/task";
import {useInvoiceStore} from "./store/star-store";

function App() {
    const {imagesPreloaded} = useLocalImagePreload();

    const navigator = useMemo(() => initNavigator('app-navigation-state'), []);
    const [location, reactNavigator] = useIntegration(navigator);
    const {user, setUser} = useUserStore();
    const {setChannels} = useChannelsStore();
    const {setLinks} = useLinksStore();
    const {setAreas} = useAreasStore();
    const {setBoosts} = useBoostsStore();
    const {setFriends} = useFriendsStore()
    const {setTasks} = useTaskStore();
    const {setPrice} = useInvoiceStore();
    const {data: areas, isLoading} = useAreas(!user);
    const {data: channels} = useChannels(!user)
    const {data: links} = useLinks(!user);
    const {data: boosts} = useBoosts(!user);
    const {data: friends} = useFriends(!user);
    const {data: tasks} = useTaskGroups(!user);
    const {data: buyingPrice, isLoading: isBuyingPriceLoading} = useBuyingPrice(!user);


    useEffect(() => {
        if (buyingPrice) {
            setPrice(() => buyingPrice.price);
        }
    }, [buyingPrice]);


    useEffect(() => {
        if (channels) {
            setChannels(channels);
        }
    }, [channels]);

    useEffect(() => {
        if (areas) {
            setAreas(() => areas);
        }
    }, [areas]);

    useEffect(() => {
        if (links) {
            setLinks(links);
        }
    }, [links]);

    useEffect(() => {
        if (boosts) {
            setBoosts(() => boosts);
        }
    }, [boosts]);

    useEffect(() => {
        if (friends) {
            setFriends(friends);
        }
    }, [friends]);


    useEffect(() => {
        if (tasks) {
            setTasks(tasks);
        }
    }, [tasks]);


    const {initDataRaw} = retrieveLaunchParams();

    useEffect(() => {
        postEvent('web_app_expand')
        postEvent('web_app_set_header_color', {color: "#3f9cb6"});
        postEvent('web_app_set_background_color', {color: "#3f9cb6"});
        postEvent('web_app_setup_swipe_behavior', {allow_vertical_swipe: false});
        postEvent('web_app_setup_closing_behavior', {need_confirmation: true});
        postEvent('web_app_setup_back_button', {is_visible: false});
    }, []);

    useEffect(() => {
        if (!user && initDataRaw) {
            authHandler(initDataRaw).then(res => {
                setUser(() => res);
            });
        }
    }, [initDataRaw]);


    //
    // useEffect(() => {
    //     setUser(() => {
    //             return {
    //                 "id": 921444052,
    //                 "balance": 0,
    //                 "first_name": "Ivan",
    //                 "language_code": "",
    //                 "access_token": "6cf013f350411ba92c0d14bb991e6893df99f8c988f81902e48390a1385ec908",
    //                 "access_token_expires_at": "2024-08-04T14:17:02.567794745Z",
    //                 "is_premium": false,
    //                 "block_level": 4,
    //                 premium_expires_at: 0
    //             }
    //         }
    //     )
    // }, []);


    useEffect(() => {
        navigator.attach();
        return () => navigator.detach();
    }, [navigator]);


    const routes = [
        {
            path: "/",
            element: <MainView/>
        },
        {
            path: "/earn",
            element: <EarnView/>
        },
        {
            path: "/boost",
            element: <BoostView/>
        },
        {
            path: "/invite",
            element: <InviteView/>
        },
        {
            path: '/game',
            element: <MatterGame/>
        },
        {
            path: '/task/:id',
            element: <TaskView/>
        }
    ];


    return (

        <>
            {(!user || isLoading || isBuyingPriceLoading || !imagesPreloaded) ?
                <LoadingView/> :
                <Router navigator={reactNavigator} location={location}>
                    <Routes>
                        {routes.map(({path, element}) => (
                            <Route key={path} path={path} element={<BackButtonProvider>{element}</BackButtonProvider>}/>
                        ))}
                    </Routes>
                </Router>
            }
        </>
    )
    //
    // const router = createBrowserRouter([
    //     {
    //         path: "/",
    //         element: <MainView/>
    //     },
    //     {
    //         path: "/earn",
    //         element: <EarnView/>
    //     },
    //     {
    //         path: "/boost",
    //         element: <BoostView/>
    //     },
    //     {
    //         path: "/invite",
    //         element: <InviteView/>
    //     },
    //     {
    //         path: '/game',
    //         element: <MatterGame/>
    //     },
    //     {
    //         path: '/task/:id',
    //         element: <TaskView/>
    //     }
    // ]);
    //
    //
    // return (
    //     <>
    //         <RouterProvider router={router}/>
    //     </>
    // )

}


export default App;
// const [floorPosition, setFloorPosition] = useState<number>(height);
// const swingFrequency = useRef<number>(0.002); // Frequency of the swing
// const swingAmplitude = useRef<number>(Math.PI / 2); // Amplitude of the swing in radians (90 degrees)
// const startTime = useRef<number>(Date.now()); // Start time of the swing
// const pendulumLength = useRef<number>(150); // Length of the pendulum

// useEffect(() => {
//     if (isRunning) {
//         const engineInstance = engine.current;
//
//         const applySwingForce = () => {
//             const elapsedTime = (Date.now() - startTime.current) * swingFrequency.current;
//             const angle = swingAmplitude.current * Math.sin(elapsedTime);
//             const x = width / 2 + pendulumLength.current * Math.sin(angle);
//             const y = 250 + pendulumLength.current * Math.cos(angle);
//
//
//             if (magnet.current) {
//                 Body.setPosition(magnet.current, {x, y});
//             }
//
//         };
//
//         Events.on(engineInstance, 'beforeUpdate', applySwingForce);
//
//         oscillationIntervalID.current = setInterval(() => {
//             Engine.update(engineInstance, 1000 / 60);
//         }, 1000 / 60); // 60 times per second
//
//         return () => {
//             clearInterval(oscillationIntervalID.current);
//             Events.off(engineInstance, 'beforeUpdate', applySwingForce);
//         };
//     }
// }, [isRunning]);
//
// useEffect(() => {
//     if (!isRunning) {
//         if (oscillationIntervalID.current) {
//             clearInterval(oscillationIntervalID.current);
//         }
//     }
// }, [isRunning]);


// const moveBlockToMagnet = () => {
//     const blockTargetY = magnet.current.position.y + 40;
//     const dy = blockTargetY - boxCurrent.position.y;
//
//     Body.translate(boxCurrent, {x: 0, y: dy},);
//
//
//     if (Math.abs(dy) > 1) {
//         requestAnimationFrame(moveBlockToMagnet);
//     } else {
//         Events.on(engine.current, 'afterUpdate', checkIfResting);
//     }
// };
//
// moveBlockToMagnet();
