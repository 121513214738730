import {API_URL, fetchSWR} from "../apiClient";
import useSWR from "swr";
import axios from 'axios'


export interface User {
    "id": number,
    "balance": number,
    "first_name": string
    "language_code": string
    "access_token": string,
    "access_token_expires_at": string,
    "premium_expires_at": number,
    "is_premium": boolean,
    "block_level": number
    is_onboarding_completed: boolean
}


export const useUserInfo = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/getMe',
        (url) => fetchSWR<User>({input: url}), {
            revalidateOnFocus: false
        }
    );
}

export interface TaskGroup {
    "id": number,
    "name": string,
}

export const useTaskGroups = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/tasks/groups',
        (url) => fetchSWR<TaskGroup[]>({input: url}), {
            revalidateOnFocus: false
        }
    );
}

export async function authHandler(initDataRaw: string) {
    const response = await axios.post(API_URL + '/authorize', initDataRaw);
    return response.data as User
}



export interface Friends {
    "friends_link": string
    "friends_share_link": string
    "friends_count":number
    "friends_profit": number
    "sub_friends_count":number
    "sub_friends_profit": number

}

export const useFriends = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/friends',
        (url) => fetchSWR<Friends>({input: url}), {
            revalidateOnFocus: false
        }
    );
}



export const useBuyingInvoiceLink = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/areas/buyingInvoiceLink',
        (url) => fetchSWR<{invoice_link: string}>({input: url}), {
            revalidateOnFocus: false,
            revalidateOnMount: false
        }
    );
}

export const useBuyingPrice = (skip?: boolean) => {
    return useSWR(skip ? undefined :
            '/areas/buyingPrice',
        (url) => fetchSWR<{price: number}>({input: url}), {
            revalidateOnFocus: false
        }
    );
}
